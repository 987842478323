import React from 'react'
import './memberships.css'
import bbb from '../../assets/BBB.jpg'
import issa from '../../assets/ISSA.png'
import bccca from '../../assets/BCCCA.png'
import worksafebc from '../../assets/worksafebc.png'
import envChoice from '../../assets/envChoice.svg'
import ecologo from '../../assets/ecologo.jpg'
import gsProvider from '../../assets/GSProvider.png'
import cognibox from '../../assets/cognibox.png'
import mycoi from '../../assets/myCOI.png'
import avettaBrowz from '../../assets/avettaBrowz.png'
import canadianGreen from '../../assets/canadian-green.jpg'

const Memberships = () => {
    return (
        <div className="qfb__memberships">
            <h1>Association Memberships</h1>
            <div className="qfb__memberships-row">
                <a href="https://www.bbb.org/ca/bc/delta/profile/janitor-service/quality-first-building-maintenance-ltd-0037-1198379" rel="noreferrer" target="_blank">
                    <img src= { bbb } alt="Better Business Bureau"/>
                </a>
                <img src= { issa } alt="ISSA Canada"/>
                <a href="https://bccca.ca/members/char/Q/?cn-cat=7&cn-s" rel="noreferrer" target="_blank">
                    <img src= { bccca } alt="British Columbia Contract Cleaners Association" />
                </a>
            </div>
            <div className="qfb__memberships-row">

                <div className="qfb__memberships-column">
                    <h3>Register Complaint</h3>
                    <a href="https://www.worksafebc.com/en/insurance/why-clearance-letter/get-clearance-letter" rel="noreferrer" target="_blank">
                        <img src= { worksafebc } alt="Work Safe BC"/>
                    </a>
                    
                </div> 

                <div className="qfb__memberships-column">
                    <h3>Sustainable Green Cleaning</h3>
                    <img src={ canadianGreen } alt="Canadian Green Sustainability Program" />
                </div>
            </div>
            
            <div className="qfb__memberships-column">
                <h3>Certified Green Products</h3>
                <div className="qfb__memberships-row">
                    <img src={ envChoice } alt="Environmental Choice"/>
                    <a href="https://www.ul.com/resources/ecologo-certification-program" rel="noreferrer" target="_blank">
                        <img src={ ecologo } alt="Ecologo" />
                    </a>
                    <img src={ gsProvider } alt="Proud Providers of Green Seal" />
                </div>
            </div>

            <div className="qfb__memberships-column">
                <h3>3rd Party Certified</h3>
                <div className="qfb__memberships-row">
                    <img src={ cognibox } alt="Cognibox" />
                    <div className="more_width_coi">
                        <img src={ mycoi } alt="MyCOI" />
                    </div>
                    <div className="more_width_avetta">
                        <img  src={ avettaBrowz } alt="AvettaBrowz" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Memberships
