import React from 'react'
import './quotes.css'
import { Link } from 'react-router-dom'

const Quotes = () => {
    return (
        <div className="qfb__quotes">
            <h2>Free Quotes</h2>
            <p>Get in touch with us for cleaning and janitorial services.</p>
            <Link to="/contact"><button>Request a Quote</button></Link>
        </div>
    )
}

export default Quotes
