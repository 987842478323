import React from 'react'
import './weGreen.css'
import { Link } from 'react-router-dom'
import greenMaple from '../../assets/greenMaple.webp'
import greenLeaf from '../../assets/greenGlobe.webp'
import { Members, Quotes } from '../../components'

const Header = () => {
    return (
        <div className="qfb__weGreen-header">
            <img src={ greenMaple } alt="Green Maple Leaf"/>
            <div className="qfb__weGreen-header_overlay-text">
                <h2> We are green</h2>
            </div>
        </div>
    )
}

const EcoServices = () => {
    return (
        <div className="qfb__weGreen-ecoServices">
            <div className="qfb__weGreen-ecoServices_delta">
                <h1><span>Eco-Friendly</span> Janitorial Services in Delta</h1>
                <p>Quality First Building Maintenance Ltd provides eco-friendly janitorial services in Delta.
                     Find out if your cleaning services are truly green! We take pride in being the Lower Mainland’s eco-friendly janitorial service.
                      Get in touch with us for more details. <Link to="/contact">Contact us</Link> now for a free quote or for a free evaluation.</p>
            </div>
            <div className="qfb__weGreen-ecoServices_cgsp">
                <img src={ greenLeaf } alt="Green Leaf" />
                <div className="qfb__weGreen-ecoServices_cgsp-content">
                    <h2>Is Your Cleaning Program Truly Green? With the CGSP, You Can Be Sure!</h2>
                    <p>If you manage a green cleaning department, the appearance of your facility isn’t the only thing you’re concerned about.
                         Your priority list probably also includes items such as the health of your tenants, the health of your building and indoor air quality,
                          along with legal and liability issues. And if you are like countless other facility managers, you also probably wonder if you are doing
                           everything you possibly can to ensure the greenest, healthiest indoor environment. If this describes you, you’ll be interested in the
                            Canadian Green Sustainability Program (CGSP).</p>
                </div>
            </div>
            <div className="qfb__weGreen-ecoServices_whatCgsp">
                <h2>What is the Canadian Green Sustainability Program?</h2>
                <p>The CGSP is an innovative training tool developed by the Canadian Sanitation Supply Association. With a strong emphasis on cleaning for health and
                     the environment, the CGSP helps business owners and facility managers maintain a truly green indoor environment.</p>
                <p>The CGSP serves as a standard for green cleaning and professionalism that facility managers can aspire to. It is not a product-specific program
                     (any EcoLogo certified or Green Seal certified product is accepted within the CGSP) but rather a comprehensive management and operations program.</p>
                <h2>How Facility Managers and Business Owners Benefit From the CGSP Program</h2>
                <p>The CGSP can be a valuable tool used to enhance a business’ reputation. Increasingly, consumers love doing business with companies known for caring about
                     their people and their indoor environment. Other important benefits include:</p>
                <ul>
                    <li>Happier building occupants and higher tenant retention that stems from a healthy indoor environment</li>
                    <li>Heightened productivity, improved attendance, and improved employee motivation</li>
                    <li>Reduced liability through healthier cleaning products and greener cleaning practices</li>
                    <li>Decreased costs of maintenance due to highly efficient floor-care practices</li>
                    <li>Other long-term cost reductions that stem from the effects of consistent green benefits</li>
                </ul>
                <h2>How Building Occupants Benefit From the CGSP Program</h2>
                <p>Harsh chemicals and sloppy housekeeping practices can lead to unfavourable indoor conditions for anyone visiting or working in a facility.
                     Among these conditions are poor indoor air quality and Sick Building Syndrome.</p>
                     <p>The guidance and training provided by the Canadian Green Sustainability Program will help your janitorial staff implement procedures that
                          lead to a healthier indoor environment. For example, the reduction of harsh chemicals and volatile organic compounds (VOCs) will improve
                           indoor air quality, creating noticeable health improvements.</p>
            </div>
        </div>
    )
}

const WeGreen = () => {
    return (
        <div className="qfb__weGreen">
            <Header />
            <div className="qfb__weGreen-content">
                <EcoServices />
            </div>
            <Quotes />
            <Members />
        </div>
    )
}

export default WeGreen
