import React from 'react'
import { Header, Memberships, ServiceAreas } from '../../containers'
import { MainContent, InsuredBonded } from '../../components'
import './home.css'

const Home = () => {
    return (
        <div>
            <Header />
            <MainContent />
            <ServiceAreas />
            <InsuredBonded />
            <Memberships />            
        </div>
    )
}

export default Home
