import React from 'react'
import 'react-slideshow-image/dist/styles.css'
import { Fade, Zoom, Slide } from 'react-slideshow-image'
import './header.css'
import { Link } from 'react-router-dom'
import banner1 from '../../assets/banner/banner1.webp'
import banner3 from '../../assets/banner/banner2.webp'
import banner2 from '../../assets/banner/banner3.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const caption1 = ()=>{
    return (
        <div className="qfb__header-content ">
            <ul>
                <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Trusted Team</li>
                <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Quality Clean</li>
                <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Certified Green</li>
            </ul>
        </div>
    )
}

const caption2 = ()=> {
    return(
        <div className="qfb__header-content brown">
            <h1 className="bg__text">Free Evaluation</h1>
            <h3>Contact us today for a free quote and professional service.</h3>
        </div>
    )
}

const caption3 = ()=> {
    return (
        <div className="qfb__header-content"> 
            <h1>Infection Control Cleaning Program</h1>
            <h3>Electrostatic Spray Disinfection - ESD</h3>
            <Link to="/infection-control"><button>Learn More</button></Link>
        </div>
    )
}
const slideImages = [
    {
        url: banner1,
        caption: caption1
    },
    {
        url: banner2,
        caption: caption2
    },
    {
        url: banner3,
        caption: caption3
    }
];

const divStyle = {
    display: 'flex',
    alignItems: "center",
    justifyContent: "right",
    paddingRight: "2em",
    height: "70vh",
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    borderTop: '1px solid black',
}

const Header = () => {
    return (
        <div className="qfb__header">
            <Fade>
                {slideImages.map((image, index) => (
                    <div key={index}>
                        <div style={{...divStyle, backgroundImage:`url(${image.url})`}} className="qfb__header-banner">
                            <image.caption />
                        </div>
                    </div>
                ))}
            </Fade>
        </div>
    )
}

export default Header
