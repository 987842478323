import React from 'react'
import './testimonials.css'
import profilePic from '../../assets/profile_pic.jpg'
import { Link } from 'react-router-dom'
import Quotes from '../../components/Quotes/Quotes'
import { Members } from '../../components'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const data = [
    {
        name: 'Kim N., Vancouver, BC',
        img: profilePic,
        header: 'Very Happy with the Work',
        review: "I am very happy with the work; you're doing a great job!"
    },
    {
        name: 'Matt J., Vancouver, BC',
        img: profilePic,
        header: 'Really Positive Comments',
        review: "The cleaning every week has been great; I've had some really positive comments about your work lately."
    },
    {
        name: 'Katheryne S., Richmond, BC',
        img: profilePic,
        header: 'Value for Your Money',
        review: "I was afraid your quality of work would be lacking as we are paying less than before. The quality is better—what a nice surprise to get value for your money."
    },
    {
        name: 'Lorene H., Surrey, BC',
        img: profilePic,
        header: 'The Best Change We Ever Made',
        review: "At first I was reluctant to switch janitorial companies but the best change we ever made was choosing Quality First Building Maintenance Ltd."
    },
    {
        name: 'Norman L., Vancouver, BC',
        img: profilePic,
        header: 'The Floors Look Awesome',
        review: "The floors look awesome and cleaning in the last month has been great."
    },
    {
        name: 'Susan G., Surrey, BC',
        img: profilePic,
        header: 'Perfect As Ever',
        review: "Your work in the last month has been as perfect as ever."
    },
    {
        name: 'Crystal N., Langley, BC',
        img: profilePic,
        header: 'Really Happy with Our Service',
        review: "I had someone trying to get us to switch companies; I told them to go away, we are really happy with our service."
    },
    {
        name: 'Sharon S., Langley, BC',
        img: profilePic,
        header: 'Everything Looks Great',
        review: "I thought it was time to change our janitorial service; boy was I wrong! I'm glad you're back cleaning. Everything looks great and I appreciate the monthly service calls."
    },
    {
        name: 'Terry K., Delta, BC',
        img: profilePic,
        header: "You're Making Me Look like a Star",
        review: "You're making me look like a star. The floors look brilliant and I'm getting all sorts of great comments on your work."
    },
    {
        name: 'Don S., Burnaby, BC',
        img: profilePic,
        header: "The Office Looks 300% Better",
        review: "Since your company has started, the office looks 300% better!"
    },
]

const Testimonials = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,

        responsive: [
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1
            }
          }
        ],
      };
    return (
        <div className="qfb__testimonials">
            <div className="qfb__testimonials-header">
                <h1>Testimonials from Our Satisfied Customers</h1>
                <p>Read testimonials from customers satisfied with the work done by Quality First Building Maintenance Ltd in Delta and areas in the Lower Mainland. We have years of
                 experience providing customers with reliable commercial janitorial service from our trained and licensed employees. Get in touch with us for a free evaluation.
                 <Link to="/contact"> Call</Link> today for a complimentary quote and experience our professionalism first-hand.</p>
            </div>
            <div className="qfb__testimonials-commentsHeader">
                <h2>Actual Comments from Our Service Calls</h2>
            </div>
            <div className="qfb__testimonials-testimonials">
                <div className="qfb__testimonials-testimonials-box">
                    <div className="qfb__testimonials-testimonials-box-holder">
                        <Slider {...settings}>
                            {data.map((d) => (
                                <div key={d.name} className="qfb__testimonials-slide">
                                    <div className="qfb__testimonials-slide-image">
                                        <img src={d.img} alt="Profile avatar" />
                                    </div>
                                    <div className="qfb__testimonials-slide-comment">
                                        <h3>{d.name}</h3>
                                        <h4>{d.header}</h4>
                                        <p>{d.review}</p>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                    
                </div>
            </div>
            <div className="qfb__testimonials-quotes">
                <Quotes />
            </div>
            <Members />
        </div>
    )
}

export default Testimonials
