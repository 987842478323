import React from 'react'
import './insuredBonded.css'
import { Link } from 'react-router-dom'
import video from '../../assets/QFBMLVideo.mp4'
import poster from '../../assets/whyUs-banner.jpg'

const InsuredBonded = () => {
    return (
        <div className="qfb__insuredBonded">
            <div className="qfb__insuredBonded-video"> 
                <video src={video} controls="controls" preload="metadata" poster={poster}/>
            </div>
            <div className="qfb__insuredBonded-content">
                <h2>Insured &amp; Bonded Employees</h2>
                <p>At Quality First Building Maintenance Ltd, our aim is to ensure <Link to='/testimonials'>complete customer satisfaction</Link> for all our jobs. 
                When you trust and choose us to keep your building clean, you can be assured that all work will be done by our fully trained, 
                insured and bonded professional janitorial crews. Your peace of mind is important to us. 
                That's why all employees are covered by our $5,000,000 Commercial General Liability Insurance policy and a 
                Comprehensive Commercial Bond.</p>
            </div>
        </div>
    )
}

export default InsuredBonded
