import React from 'react'
import './commercialCleaning.css'
import { Link } from 'react-router-dom'

const CommercialCleaning = () => {
    return (
        <div className="qfb__commercialCleaning">
            <h2>Commercial Cleaning</h2>
            <p>We offer smart janitorial services for commercial clients in Delta.</p>
            <Link to="/contact"><button>Get in Touch</button></Link>
        </div>
    )
}

export default CommercialCleaning
