import React from 'react'
import './esdDetail.css'
import difference from '../../assets/esd/esd-difference.png'
import coverage from '../../assets/esd/coverage.jpg'

const EsdDetail = () => {
    return (
        <div className="qfb__esdDetail">
            <h1>Quality First Infection Control Cleaning Program Includes Weekly Electrostatic Spray Disinfection With Every Contract</h1>
            <h2>A blanket of Disinfection for all touchpoints</h2>
            <p>ESD adds a level of protection for the most frequently touched surfaces and entire rooms like we have never seen before. 
                An ongoing disinfecting procedure is a requirement for any disease-prevention protocol.</p>
            <img src={ difference } alt="The electrostatic difference" />
            <p>Hard-to-reach surfaces that manual disinfection could miss are no longer a concern. Cutting edge patented electrostatic spray technology
                 delivers precise application of disinfectant with 3x the coverage of traditional sprayers or wipes. 
                 Electrostatic sprayers change the disinfectant solution ions to a “double positive electric charge” and have an attraction of up to 75x
                  that of gravity seeking out items to land on and “wrap around” versus floating “in the air” - similar to a magnet.
                   This magnetic effect leads to more even coverage which increases efficiency.</p>
            <img src={ coverage } alt="Electrostatic disinfectant coverage" />
            <p>Coronavirus has quickly gained a reputation for being particularly insidious. It can survive on surfaces for days and people infected by the
                 virus don’t show symptoms until approximately two weeks after infection, making it difficult to track and contain. 
                 This is why having a trusted professional disinfection team with an infection control program in place is so important.</p>
        </div>
    )
}

export default EsdDetail
