import { useEffect, useState } from 'react'
import './scrollToTop.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'


const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)

    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  return (
    <div className="qfb__scrollToTop">
      <button
        type="button"
        onClick={scrollToTop}
        className={
          isVisible ? 'qfb__scrollToTop-button' : 'qfb__scrollToTop-button-invisible'
        }
      >
        <FontAwesomeIcon icon={faAngleUp} size="2xl" style={{color: "#ffffff",}} />
      </button>
    </div>
  )
}

export default ScrollToTop