import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import './contact.css'
import { Members } from '../../components'
import { Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha"
import validator from "validator"

const Contact = () => {

    const form = useRef();

    const captchaRef = useRef(null);

    const [statusMessage, setStatusMessage] = useState("");

    const [error, setError] = useState("");

    const [emailError, setEmailError] = useState("");

    const [phoneError, setPhoneError] = useState("");

    const validateEmail = (e) => {
        const email = e.target.value;
        if(!validator.isEmail(email)){
            setEmailError("Please, enter a valid Email!")
        }else{
            setEmailError("")
            setStatusMessage("")
        }
    }

    const validatePhone = (e) => {
        const phone = e.target.value;
        if(!validator.isMobilePhone(phone, "en-CA")){
            setPhoneError("Please, enter a valid phone number!")
        }else{
            setPhoneError("")
            setStatusMessage("")
        }
    }

    const handleSubmit = (e) => {

        e.preventDefault();

        let token = captchaRef.current.getValue();

        if(emailError){
            alert("Please provide correct information")
            return
        }
        if(phoneError){
            alert("Please provide correct information")
            return
        }
        if(token) {
            sendEmail(e);
            captchaRef.current.reset();
        }else{
            alert("Please verify that you are not a robot!")
        }
        
    }

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_aqsu428', 'template_kms8xwe', form.current, '-ayMESHTzjNWMDCSY')
        .then((result) => {
            console.log(result.text);
            alert("Form submitted Successfully! We will get back to you.")
        }, (error) => {
            console.log(error.text);
            alert("Error, please try again!}")
            setStatusMessage(`${error.text} happened`)
            setTimeout(() => {
                setStatusMessage("")
            }, 5000) // Hide the message after 5 seconds
        });
        e.target.reset();
    };

    const onChange = (value) => {
        console.log("Captcha value:", value);
        if (!value) {
            alert("Please verify the reCAPTCHA!");
          }
        setError("")
    }

    return (      
        <div className="qfb__contactUs">
            <div className="qfb__contactUs-form_container">
                <div className="qfb__contactUs-form">
                    <div className="qfb__contactUs-form_col1">
                        <h2><strong>Get A FREE </strong> Cleaning Quote</h2>
                        <p>Fill out the online form below for an email response by the next business day. You can also share your inquiries or comments with our team by filling out the
                            same online form. We look forward to hearing from you!</p>
                        <form ref={form} onSubmit={handleSubmit}>
                            <label>Name*</label>
                            <input type="text" name="user_name" required="required" />
                            <label>Email*</label>
                            <p className="incorrectEntry">{emailError}</p>
                            <input type="email" name="user_email" required="required" onChange={(e) => validateEmail(e)} />
                            <label>Phone*</label>
                            <p className="incorrectEntry">{phoneError}</p>
                            <input type="tel" name="phone" required="required" onChange={(e) => validatePhone(e)} />
                            <label>Company*</label>
                            <input type="text" name="company" required="required" />
                            <label>Message*</label>
                            <textarea name="message" required="required" />
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                ref={captchaRef}
                                onChange={onChange}
                            />
                            <p className="statusMessage">{error}</p>
                            <input type="submit" value="Send" />
                        </form>
                        <p className="statusMessage">{statusMessage}</p>
                    </div>
                    <div className="qfb__contactUs-form_col2">
                        <h3>Is your commercial space<br /> in need of a <span>fresh, clean look?</span></h3>
                        <h3>Contact the trusted professionals at <span>Quality First Building Maintenance Ltd.</span></h3>
                        <h3>With years of experience, we offer <span>top-notch cleaning services</span> in the Lower Mainland. </h3>
                        <h3>View our quality work in the <Link to='/gallery'>gallery</Link> and reach out to discuss your needs or learn more about our services today.</h3>
                    </div>
                </div>
            </div>
            {/*
            <div className="qfb__contactUs-map">
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2608.460055438415!2d-122.89324552453924!3d49.17285617852653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485d9ac86d76219%3A0x348e9a243c44250!2s9357%20120%20St%2C%20Delta%2C%20BC%20V4C%206R8!5e0!3m2!1sen!2sca!4v1699568632516!5m2!1sen!2sca" 
                    width="100%" 
                    height="450" 
                    title="Map location of Quality First Building Maintenance Ltd."
                    style={{border:"0"}}
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
            */}
            <Members />
        </div>
    )
}

export default Contact
