import React from 'react'
import './serviceAreas.css'
import bg_serviceAreas from '../../assets/serviceArea.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const rightArrow = <FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} />

const ServiceAreas = () => {
    return (
        <div className="qfb__serviceAreas">
            <img src={ bg_serviceAreas } alt="Vancouver skyline"/>
            <div className="qfb__serviceAreas-overlay-text">
                <h2>Our Service Areas Include:</h2>
                <div class="qfb__serviceAreas-overlay-text_row">
                    <div class="qfb__serviceAreas-overlay-text_column">
                        <ul>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Delta</li>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Ladner</li>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Burnaby</li>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Coquitlam</li>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Richmond</li>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Port Moody</li>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Tsawwassen</li>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Pitt Meadows</li>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> North Vancouver</li>
                        </ul>
                    </div>
                    <div class="qfb__serviceAreas-overlay-text_column">
                    <ul>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Surrey</li>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Langley</li>                        
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> White Rock</li>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Cloverdale</li>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Vancouver</li>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Maple Ridge</li>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> Port Coquitlam</li>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> West Vancouver</li>
                            <li><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /> New Westminster</li>                       
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default ServiceAreas
