import React from 'react'
import './esd.css'
import banner from '../../assets/services/esd.webp'
import { EsdDetail } from '../../containers'
import { WhyEsd } from '../../components'
import { Members } from '../../components'

const Header = () => {
    return (
        <div className="qfb__esd-header">
            <img src={ banner } alt="Disinfecting Service"/>
            <div className="qfb__esd-header_ovrlay-text">
                <h2>Infection Control Program</h2> 
                <h2> with </h2>
                <h2>ElectroStatic Spray Disinfection</h2>
            </div>
        </div>
    )
}

const Esd = () => {
    return (
        <div>
            <Header />
            <div className="qfb__esd">
                <EsdDetail />
                <WhyEsd />
            </div>
            <Members />
        </div>
    )
}

export default Esd
