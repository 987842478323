import React from 'react'
import './gallery.css'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Link } from 'react-router-dom'
import { Quotes, Members } from '../../components'
import before1 from '../../assets/before-after/1-1.webp'
import after1 from '../../assets/before-after/1.webp'
import before2 from '../../assets/before-after/2.webp'
import after2 from '../../assets/before-after/2-1.webp'
import before3 from '../../assets/before-after/3.webp'
import after3 from '../../assets/before-after/3-1.webp'
import before4 from '../../assets/before-after/4.webp'
import after4 from '../../assets/before-after/4-1.webp'
import before5 from '../../assets/before-after/5.webp'
import after5 from '../../assets/before-after/5-1.webp'
import before6 from '../../assets/before-after/6-1.webp'
import after6 from '../../assets/before-after/6.webp'
import before7 from '../../assets/before-after/7.webp'
import after7 from '../../assets/before-after/7-1.webp'

const data = [
    {
        index: 1,
        before: before1,
        after: after1,
    },
    {
        index: 2,
        before: before2,
        after: after2,
    },
    {
        index: 3,
        before: before3,
        after: after3,
    },
    {
        index: 4,
        before: before4,
        after: after4,
    },
    {
        index: 5,
        before: before5,
        after: after5,
    },
    {
        index: 6,
        before: before6,
        after: after6,
    },
    {
        index: 7,
        before: before7,
        after: after7,
    },
]


const Gallery = () => {

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };

    return (
        <div className="qfb__gallery">
            <div className="qfb__gallery-header">
                <h1 className="text-2xl font-bold">Check Out Our Latest Before and After Photographs</h1>
                <p>See it to believe it! Quality First Building Maintenance Ltd saved this customer the cost of a new floor. We will help determine the best maintenance schedule to
                    protect your carpet and flooring investments. Our highly trained technicians can bring new life to any floor surface. We use the best products in the business to
                    get that deep clean you want. Get in touch with us for free quotes or for a free evaluation.</p>
                    <p><Link to="/contact">Call</Link> today and schedule an appointment with us while you browse through our photos!</p>
            </div>
            <div className="qfb__gallery-beforeAfter">
                <Carousel
                    arrows={true}
                    swipeable={true}
                    showDots={true}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={2000}
                    transitionDuration={20}
                    focusOnSelect={true}
                    keyBoardControl={true}
                >
                    {data.map((d) => (
                        <div className="qfb__gallery-beforeAfter_slide">
                            <div className="qfb__gallery-before">
                                <h2>Before</h2>
                                <img src={d.before} alt="before cleaning"/>
                            </div>
                            <div className="qfb__gallery-after">
                                <h2>After</h2>
                                <img src={d.after} alt="after cleaning" />
                            </div>
                        </div>
                    ))}
                </Carousel>;

            </div>
            <div className="qfb__gallery-quotes">
                <Quotes />
            </div>
            <Members />
        </div>
    )
}

export default Gallery
