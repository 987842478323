import React from 'react'
import './rightDisinfectant.css'
import { Link } from 'react-router-dom'
import oxyQ from '../../assets/esd/oxy-q.webp'
import quatPlus from '../../assets/esd/quat-plus.webp'
import uniTab from '../../assets/esd/uniTab.webp'
import QuatromyicideII from '../../assets/esd/QuatromyicideII.webp'

const RightDisinfectant = () => {
    return (
        <div className="qfb__rightDisinfectant">
            <h2>Choosing the Right Disinfectant is Key</h2>
            <p>Foreign approvals such as EPA and FDA are non-transferable to Canada and are not approved for use. </p>
            <p>All registered disinfectants will have a Drug Identification Number (DIN) provided by Health Canada. 
                We use registered industrial-strength medical-grade disinfectants with a broad spectrum kill claim. </p>
            <div className="qfb__rightDisinfectant-images">
                <div className="qfb__rightDisinfectant-images_image">
                    <img src={ oxyQ } alt="Oxy Q" />
                    <p>Oxy Q</p>
                    <p>DIN 02457146</p>
                </div>
                <div className="qfb__rightDisinfectant-images_image">
                    <img src={ quatPlus } alt="Quat Plus" />
                    <p>Quat Plus</p>
                    <p>DIN 02298694</p>
                </div>
                <div className="qfb__rightDisinfectant-images_image">
                    <img src={ uniTab } alt="UniTab" />
                    <p>UniTab</p>
                    <p>DIN 02470381</p>
                </div>
                <div className="qfb__rightDisinfectant-images_image">
                    <img src={ QuatromyicideII } alt ="Quatromyicide II"/>
                    <p>Quatromyicide II</p>
                    <p>DIN 02321963</p>
                </div>
            </div>

            <div className="qfb__rightDisinfectant-join">
                <h2>Join In Our Infection Control Program</h2>
                <p>Successfully sanitize and disinfect hard-to-reach surfaces with the help of our Electrostatic Sprayers.</p>
                <Link to="/contact"><button>Get In Touch</button></Link>
            </div>
        </div>
    )
}

export default RightDisinfectant
