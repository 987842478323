import React from 'react'
import './servicesHeader.css'
import electrostatic from '../../assets/services/esd.webp'
import pressureWashing from '../../assets/services/pressure-washing.webp'
import windoWashing from '../../assets/services/window-washing.webp'
import blindWashing from '../../assets/services/blind_washing.webp'
import waxBuff from '../../assets/services/waxBuff.webp'
import steamCleaning from '../../assets/services/steam-cleaning.webp'
import powerScrub from '../../assets/services/power-scrub.webp'
import { Fade, Zoom, Slide } from 'react-slideshow-image'

const caption1 = () => {
    return (
        <div className="qfb__servicesHeader-caption">
            <h1>Pressure Washing</h1>
        </div>
    )
}

const caption2 = () => {
    return (
        <div className="qfb__servicesHeader-caption">
            <h1>Electrostatic Spray Disinfection</h1>
        </div>
        
    )
}

const caption3 = () => {
    return (
        <div className="qfb__servicesHeader-caption">
            <h1>Window Washing</h1>
        </div>
    )
}

const caption4 = () => {
    return (
        <div className="qfb__servicesHeader-caption darker">
            <h1>Blind Washing</h1>
        </div>
    )
}

const caption5 = () => {
    return (
        <div className="qfb__servicesHeader-caption">
            <h1>Strip Seal &#38; Wax Buff Floors</h1>
        </div>
    )
}

const caption6 = () => {
    return (
        <div className="qfb__servicesHeader-caption darker">
            <h1>Carpet Steam Cleaning</h1>
        </div>
    )
}

const caption7 = () => {
    return (
        <div className="qfb__servicesHeader-caption">
            <h1>Power Scrub Tile/Cement Floor</h1>
        </div>
    )
}

const image1 = () => {
    return (
        <img src={pressureWashing} alt="Pressure Washing" />
       
    )
}

const image2 = () => {
    return (
        <img src={electrostatic} alt="Electrostatic Spray Disinfection" />
    )
}
const image3 = () => {
    return (
        <img src={windoWashing} alt="Window Washing" />
    )
}
const image4 = () => {
    return (
        <img src={blindWashing} alt="Blind Washing" />
    )
}
const image5 = () => {
    return (
        <img src={waxBuff} alt="Strip Seal and Wax Floor Buffing" />
    )
}
const image6 = () => {
    return (
        <img src={steamCleaning} alt="Carpet Steam Cleaning" />
    )
}
const image7 = () => {
    return (
        <img src={powerScrub} alt="Power Scrub Floor" />
    )
}

const slideImages = [
    {
        url: image1,
        caption: caption1
    },
    {
        url: image2,
        caption: caption2
    },
    {
        url: image3,
        caption: caption3
    },
    {
        url: image4,
        caption: caption4
    },
    {
        url: image5,
        caption: caption5
    },
    {
        url: image6,
        caption: caption6
    },
    {
        url: image7,
        caption: caption7
    }
];

const divStyle = {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',  
    borderTop: '1px solid black',
}



const ServicesHeader = () => {
    return (
        <div className="qfb__header">
            <Zoom>
                {slideImages.map((image, index) => (
                    <div key={index}>
                        <div style={{...divStyle }} className="qfb__servicesHeader">
                            <image.url />
                            <image.caption />
                        </div>
                    </div>
                ))}
            </Zoom>
        </div>
    )
}

export default ServicesHeader
