import React from 'react'
import './services.css'
import { ServicesHeader } from '../../containers'
import { Members, Quotes, CommercialCleaning, ExtraServicesDetail } from '../../components'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import extraServices from '../../assets/services/extra-services.webp'
import cleaningPic from '../../assets/services/customers_section.webp'

const FullServices = () => {
    return (
        <div className="qfb__services-fullServices">
            <h1><span>Full-Service</span> Commercial Cleaning Services in Lower Mainland</h1>
            <p>Quality First Building Maintenance Ltd offers commercial cleaning services in Vancouver, Burnaby, New Westminster, Coquitlam, Delta, Richmond, Surrey, White-rock, Langley, Abbotsford and more! 
                We also offer a customized janitorial program that allows us to meet all of your cleaning needs at an affordable rate to fit your budget. 
                From daily to once a month, we provide cleaning services in Delta and surrounding areas for businesses large and small.
                <Link to="/contact"> Get in touch</Link> with us for a free evaluation or a free quote.</p>
        </div>
    )
}

const ExtraServices = () => {
    return (
        <div className="qfb__services-extraServices">
            <img src={ extraServices } alt="Cleaning services"/>
            <div className="qfb__services-extraServices-content">
                <h2>Quality First Building Maintenance Ltd Offers a Full Range of Extra Services</h2>
                <ul>
                    <li><HashLink to="/services#extraServices">Strip, seal and wax tile floor</HashLink></li>
                    <li><HashLink to="/services#extraServices">Buffing floor</HashLink></li>
                    <li><HashLink to="/services#extraServices">Power scrub ceramic tile/cement floor</HashLink></li>
                    <li><HashLink to="/services#extraServices">Steam clean carpet</HashLink></li>
                    <li><HashLink to="/services#extraServices-2">Window cleaning – in and out</HashLink></li>
                    <li><HashLink to="/services#extraServices-2">Blind washing</HashLink></li>
                    <li><HashLink to="/services#extraServices-2">Pressure washing</HashLink></li>
                </ul>
            </div>
        </div>
    )
}

const Customers = () => {
    return (
        <div className="qfb__services-customers_container">
            <div className="qfb__services-customers">
                <h1><Link to="/green-products">View SDS Sheets</Link></h1>
                <div className="qfb__services-customers_content">
                    <div className="qfb__services-customers_content_info">
                        <h3>Some of <span>Our Customers</span> Include:</h3>
                        <ul>
                            <li>Office buildings</li>
                            <li>Couriers</li>
                            <li>Medical clinics</li>
                            <li>Community centres</li>
                            <li>Schools</li>
                            <li>Day care centres</li>
                            <li>Dental clinics</li>
                            <li>Food processing plants</li>
                            <li>Caterers</li>
                            <li>Churches</li>
                            <li>Veterinary clinics</li>
                            <li>Horticulture nurseries</li>
                            <li>Car dealerships</li>
                            <li>Showrooms</li>
                            <li>Distribution centres</li>
                            <li>Transportation companies</li>
                            <li>Gyms</li>
                            <li>Machine shops</li>
                            <li>Manufacturing plants</li>
                            <li>Property management</li>
                        </ul> 
                        <p>We only service commercial properties. Our specialty is janitorial maintenance for commercial properties. <Link to="/contact-us">Get in touch </Link> with us for more details.</p>           
                    </div>
                    <img src={ cleaningPic } alt="Cleaning Service"/>
                </div>
            </div>
        </div>
    )
}

const Services = () => {
    return (
        <div>
            <ServicesHeader />
            <div className="qfb__services">      
                <FullServices />
                <ExtraServices />
            </div>
            <Customers />
            <ExtraServicesDetail />
            <div className="qfb__services-row">
                <CommercialCleaning />
                <Quotes />
            </div> 
            
            <Members />
        </div>
    )
}

export default Services
