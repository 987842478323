import React from 'react'
import { AboutUs } from '../../containers'
import { Members } from '../../components'
import './whyUs.css'
import banner from '../../assets/whyUs-banner.webp'
import { Link } from 'react-router-dom'

const WhyChooseUs = () => (
    <div className="qfb__whyUs-banner">
        <img src={ banner } alt="Cleaning team" />
    </div>
)

const Facts = () => (
    <div className="qfb__whyUs-facts">
        <h2><span>Important Facts</span> About Our Service</h2>
        <p>Quality First Building Maintenance Ltd in the Lower Mainland is your professional answer to all your building cleaning needs. 
        We are locally owned and our team of highly skilled janitorial crews are fully bonded and insured. We strive to provide you with a clean, 
        healthy work space for your peace of mind.</p>
        <Link to="/contact"><p className="qfb__whyUs-facts_contact">Contact us to request a quote.</p></Link>
    </div>
)


const WhyUs = () => {
    return (
        <div>
            <WhyChooseUs />
            <div className="qfb__whyUs">
                <Facts />
                <AboutUs />
            </div>
            <Members />
        </div>
    )
}

export default WhyUs
