import React from 'react'
import './aboutUs.css'
import Quotes from '../../components/Quotes/Quotes'
import pic from '../../assets/aboutUs.webp'

const LearnMore = () => (
    <div className="qfb__aboutUs-learnMore">
        <img src={ pic } alt="We are green"/>
        <div className="qfb__aboutUs-learnMore_content">
            <h2>Learn More <span>About Us</span></h2>
            <p>
            Quality First Building Maintenance Ltd is a 20+ year old non-franchised, locally-owned and operated commercial janitorial company. 
            We are a Canadian Green Sustainable Contractor. Our employees are responsible for our success and therefore are our most valuable asset. 
            We have a dedicated, productive team who enjoy work and actually have fun while getting the job done.
            </p>
            <p>
            At Quality First Building Maintenance Ltd, we foster a unique work environment that cultivates excellence while promoting self-development and 
            new ideas to further our growth. We encourage advancement opportunities, support employee success through collaborative team efforts while allowing 
            individuals to shine.
            </p>
        </div>
    </div>
)

const Facts = () => (
    <div className="qfb__aboutUs-facts">
        <h2>Here are some <span>important facts</span> about our service:</h2>
        <ul>
            <li>Quality First Building Maintenance Ltd is 100% BC-owned and operated. We are not a franchise and we do not sell our contracts.</li>
            <li>Quality First Building Maintenance Ltd is licensed, fully insured and bonded. We have over 20 years of professional janitorial experience.</li>
            <li>We offer a month-to-month contract. You are not tied into a long-term contract only to see the quality of service diminish after a few months.</li>
            <li>Quality First Building Maintenance Ltd is an A+ member of the Better Business Bureau, the BC Contract Cleaners Association and is registered with WorkSafe BC.</li>
            <li>The environment is very important to us. We use EcoLogo cleaning products at no extra charge to our customers. We are a Canadian Green Sustainable Contractor.</li>
            <li>We maintain a two-week training course for new employees, with further on-the-job-supervised training. Our uniformed staff carry out all functions 
                so the quality of work is fully under our control.</li>
            <li>At Quality First Building Maintenance Ltd, our professionally trained team leaders, supervisors and management personnel constantly monitor, 
                inspect and motivate our staff to ensure consistent quality results.</li>
            <li>Our Customer Service Department (Monday - Friday, 9-5) will maintain contact with you as part of our customer satisfaction program, can arrange (at your convenience) daytime 
                inspections and encourages your feedback so that we can respond to requests or comments immediately. Also available 24-7 after hours emergency service.</li>
            <li>Quality First Building Maintenance Ltd offers a full range of extra services such as floor stripping, sealing, waxing and buffing, carpet and 
                upholstery cleaning, initial cleaning, blinds and window cleaning, pressure washing, and move in or out clean ups. 
                We also sell soap, paper supplies, garbage bags, air fresheners and dispensers.</li>
            <li>Our rates are competitive, open to review and all work Quality First Building Maintenance Ltd does is guaranteed to please you.</li>
        </ul>
    </div>
)

const AboutUs = () => {
    return (
        <div className="qfb__aboutUs">
            <LearnMore />
            <Facts />
            <Quotes />
        </div>
    )
}

export default AboutUs
