import React from 'react'
import './mainContent.css'
import { Link } from 'react-router-dom'
import bg from '../../assets/mainContent.webp'

const MainContent = () => {
    return (
        <div className="qfb__mainContent">
            <div className="qfb__mainContent-boxes">
                <div className="qfb__mainContent-box">
                    <h2>Certified Green Products</h2>
                    <p>All our products and services are certified environment friendly.
                    </p>
                    <Link to="green-products"><button>Learn More</button></Link>
                </div>
                <div className="qfb__mainContent-box">
                    <h2>Why Choose Us?</h2>
                    <p>We are a locally owned, fully insured and bonded company.</p>
                    <Link to="/about"><button>Learn More</button></Link>
                </div>
                <div className="qfb__mainContent-box">
                    <h2>Janitorial Services</h2>
                    <p>We carry out a wide range of specialist commercial janitorial and cleaning services.</p>
                    <Link to="/services"><button>Services</button></Link>
                </div>
            </div>
            <div className="qfb__mainContent-message">
                <div className="qfb__mainContent-message_image">
                    <img src={ bg } alt="Cleaning service" />
                </div>
                <div className="qfb__mainContent-message_info">
                    <h2>Get Janitorial Services in Delta and Surrounding Areas</h2>
                    <p>Quality First Building Maintenance Ltd offers janitorial services in Lower Mainland for all commercial clients. 
                    We are a 100% BC-owned and operated company. With our main office located in Delta, we are well-known for our dependable janitorial services in the Lower Mainland. 
                    You can count on our dedicated employees to provide prompt and professional service for all your building management needs. We provide regular, everyday maintenance of your facility. 
                    Contact us for further information or to <a href="contact">request a quote</a></p>
                    <h2>Long-term Relationship &ndash; Not Contracts</h2>
                    <p>Quality First Building Maintenance Ltd is proud of our long-term relationships with our customers. We provide customized programs so you get the most value for your money and are 
                    not locked into any long-term contract only to see the quality of service diminish after a few months.</p>
                </div>
            </div>
        </div>
    )
}

export default MainContent
