import React from 'react'
import './members.css'
import bbb from '../../assets/BBB.jpg'
import pledge from '../../assets/pledge.webp'
import issa from '../../assets/ISSA.png'
import bccca from '../../assets/BCCCA.png'
import canadianGreen from '../../assets/canadian-green.jpg'
import workSafe from '../../assets/worksafebc.png'
import ecologo from '../../assets/ecologo.jpg'
import envChoice from '../../assets/envChoice.svg'
import gsProvider from '../../assets/GSProvider.png'
import cognibox from '../../assets/cognibox.png'
import myCoi from '../../assets/myCOI.png'
import avettaBrowz from '../../assets/avettaBrowz.png'


const Members = () => {
    return (
        <div className="qfb__members">
        <img src= { bbb } alt="Better Business Bureau"/>
        <img src= { pledge } alt="Covid-19 Working Safer Pledge"/>
        <img src= { issa } alt="ISSA Canada"/>
        <img src= { bccca } alt="British Columbia Contract Cleaners Association" className='qfb__members-image_smaller'/>
        <img src={ canadianGreen } alt="Canadian Green Sustainability Program" />
        <img src= { workSafe } alt="Work Safe BC"/>
        <img src={ ecologo } alt="Ecologo" />
        <img src={ envChoice } alt="Environmental Choice"/>
        <img src={ gsProvider } alt="Proud Providers of Green Seal" />
        <img src={ cognibox } alt="Cognibox" />
        <img src={ myCoi } alt="MyCOI" className='qfb__members-image_smaller'/>
        <img  src={ avettaBrowz } alt="AvettaBrowz" className='qfb__members-image_smaller'/>
    </div>
    )
}

export default Members
