import React from 'react'
import './certifiedELProducts.css'
import { Fade, Zoom, Slide } from 'react-slideshow-image'
import ecoLogo1 from '../../assets/ecoLogo/ecoLogo1.png'
import ecoLogo2 from '../../assets/ecoLogo/ecoLogo2.png'
import ecoLogo3 from '../../assets/ecoLogo/ecoLogo3.png'
import ecoLogo4 from '../../assets/ecoLogo/ecoLogo4.png'
import ecoLogo5 from '../../assets/ecoLogo/ecoLogo5.png'
import ecoLogo6 from '../../assets/ecoLogo/ecoLogo6.png'
import { Products } from '../../containers'
import { Members, Quotes } from '../../components'

const caption = () => {
    return (
        <div className="qfb__certifiedELProducts-header_caption">
            <h1>Certified Eco Logo Products</h1>
        </div>
    )
}

const slideImages = [
    {
        url: ecoLogo1,
        caption: caption
    },
    {
        url: ecoLogo2,
        caption: caption
    },
    {
        url: ecoLogo3,
        caption: caption
    },
    {
        url: ecoLogo4,
        caption: caption
    },
    {
        url: ecoLogo5,
        caption: caption
    },
    {
        url: ecoLogo6,
        caption: caption
    }
];

const divStyle = {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    height: "60vh",
    maxHeight: "700px",
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    borderTop: '1px solid black',
    padding: '2em',
}

const Header = () => {
    return (
        <div className="qfb__certifiedELProducts-header">
            <Fade>
                {slideImages.map((image, index) => (
                    <div key={index}>
                        <div style={{...divStyle, backgroundImage:`url(${image.url})`}} className="qfb__certifiedELProducts-header_image">
                            <image.caption />
                        </div>
                    </div>
                ))}
            </Fade>
        </div>
    )
}

const ProductsUsed = () => {
    return (
        <div className="qfb__certifiedELProducts-productsUsed">
            <h1>Eco-Friendly Products Used by Quality First Building Maintenance Ltd</h1>
            <div className="qfb__certifiedELProducts-productsUsed_row">
                <div className="qfb__certifiedELProducts-productsUsed_column">
                    <h3>Wood Wyant Inc</h3>
                    <p>440 Passmore Ave. Scarborough, ON, M1V 5J8</p>
                    <p><a href="tel:1-800-361-7691">1-800-361-7691</a></p>
                    <p><a href="http://www.woodwyant.com" rel="noreferrer" target="_blank">www.woodwyant.com</a></p>
                </div>
                <div className="qfb__certifiedELProducts-productsUsed_column">
                    <h3>Dustbane Products Ltd</h3>
                    <p>25 Pickering Place, Ottawa, ON, K1G 5P4</p>
                    <p><a href="tel:1-800-387-8226">1-800-387-8226</a></p>
                    <p><a href="http://www.dustbane.ca" rel="noreferrer" target="_blank">www.dustbane.ca</a></p>
                </div>
                <div className="qfb__certifiedELProducts-productsUsed_column">
                    <h3>Prism Care Corporation</h3>
                    <p>1420 Cornwall Rd. Oakville Unit 3 ON, L6J 7W5</p>
                    <p><a href="tel:1-888-538-3300">1-888-538-3300</a></p>
                    <p><a href="http://www.prismcare.ca" rel="noreferrer" target="_blank">www.prismcare.ca</a></p>
                </div>
            </div>
        </div>
    )
}


const CertifiedELProducts = () => {
    return (
        <div className="qfb__certifiedELProducts">
            <Header />
            <div className="qfb__certifiedELProducts-content">
                <ProductsUsed />
                
            </div>
            <Products />
            <div className="qfb__certifiedELProducts-quotes">
                <Quotes />
            </div>    
            <Members />
        </div>
    )
}

export default CertifiedELProducts
