import React from 'react'
import './whyEsd.css'
import { RightDisinfectant } from '../../components/'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import liquidAdhesion from '../../assets/esd/liquidAdhesion.webp'
import reduceCC from '../../assets/esd/reduceCC.webp'
import dwellTime from '../../assets/esd/dwellTime.webp'
import touchlessApp from '../../assets/esd/touchlessApp.webp'
import ecoFriendly from '../../assets/esd/ecoFriendly.webp'

const yes = <FontAwesomeIcon icon={faCheck} style={{color: "#6fcc65"}} size={"lg"}/>
const no = <FontAwesomeIcon icon={faXmark} style={{color: "#dc0404",}} size={"lg"}/>

const data = [
    { header: "Easy to use", trigger: yes, foggers: no, es: yes },
    { header: "Covers large surfaces", trigger: no, foggers: yes, es: yes },
    { header: "Solution usage", trigger: "large", foggers: "large", es: "minimal" },
    { header: "Effectiveness", trigger: "inadequate", foggers: "inadequate", es: "optimal" },
    { header: "360º coverage", trigger: no, foggers: no, es: yes },
    { header: "Even coverage", trigger: no, foggers: no, es: yes },
    { header: "Portable", trigger: yes, foggers: no, es: yes },
    { header: "Ok on soft furnishings", trigger: yes, foggers: yes, es: yes },
    { header: "Safe operation", trigger: yes, foggers: no, es: yes }
]

const Table = () => {
    return (
        <div className="qfb__whyEsd-table">
                <table>
                    <tr>
                        <th></th>
                        <th className="qfb__whyEsd-table_bolder">Trigger Sprayers</th>
                        <th className="qfb__whyEsd-table_bolder">Foggers</th>
                        <th className="qfb__whyEsd-table_bolder theme_blue">Electrostatic Sprayers </th>
                    </tr>
                    {data.map((val, key) => {
                        return (
                            <tr key={key}>
                                <td className="qfb__whyEsd-table_bolder">{val.header}</td>
                                <td>{val.trigger}</td>
                                <td>{val.foggers}</td>
                                <td>{val.es}</td>
                            </tr>
                        )
                    })}
                </table>
            </div>
    )
}

const Features = () => {
    return (
        <div className="qfb__whyEsd-features">
            <div className="qfb__whyEsd-features_row">
                <img src={ liquidAdhesion } alt="Liquid Adhesion &#38; Coverage"/>
                <div className="qfb__whyEsd-features_row-column">
                    <h2>Liquid Adhesion &#38; Coverage</h2>
                    <p>As fully charged droplets hit the surface, they create an even spread. Particles hold their cationic charge for approx
                          2-3 seconds — preventing drips. This allows the solution to cover hidden and shadowed areas.</p>
                </div>
            </div>

            <div className="qfb__whyEsd-features_row">
                <img src={ reduceCC } alt="Reduce Cross-Contamination" />
                <div className="qfb__whyEsd-features_row-column">
                    <h2>Reduces Cross-Contamination</h2>
                    <p>Spray and wipe techniques can move bacteria from one surface to another. 
                        Cross-contamination is reduced due to the touchless effect of the electrostatic sprayer. </p>
                </div>
            </div>

            <div className="qfb__whyEsd-features_row">
                <img src={ dwellTime } alt="Dwell Time" />
                <div className="qfb__whyEsd-features_row-column">
                    <h2>Dwell Time</h2>
                    <p>The standard 3-in-1 nozzle (40, 80, 110 microns) design meets disinfectant required dwell time. </p>
                </div>
            </div>

            <div className="qfb__whyEsd-features_row">
                <img src={ touchlessApp } alt="Touchless Application" />
                <div className="qfb__whyEsd-features_row-column">
                    <h2>Touchless Application</h2>
                    <p>There is no need to touch or wipe the surfaces (dependent on the solution used).</p>
                </div>
            </div>

            <div className="qfb__whyEsd-features_row">
                <img src={ ecoFriendly } alt="Eco Friendly" />
                <div className="qfb__whyEsd-features_row-column">
                    <h2>Eco Friendly</h2>
                    <p>An eco-friendly approach to disinfection by spraying up to 65% less chemicals per square foot.</p>
                </div>
            </div>
        </div>
    )
}

const EsCustomers =() => {
    return (
        <div className="qfb__whyEsd-customers">
            <h2>Our Electrostatic Sprayers are used by:</h2>
            <div className="qfb__whyEsd-customers_column">
                <ul>
                    <li>Delta airlines</li>
                    <li>Corporate office buildings</li>
                    <li>Marriott hotels</li>
                    <li>Medical/dental clinics</li>
                    <li>New York Jets</li>
                    <li>Food processing plants</li>
                    <li>Atlanta Falcons</li>
                    <li>Property management companies</li>
                    <li>K-12 schools/universities</li>
                    <li>Community centres</li>
                    <li>Houses of worship</li>
                    <li>R&#38;D facilities</li>
                    <li>Gyms</li>
                </ul>
            </div>
        </div>
    )
}

const CbeforeD = () => {
    return (
        <div className="qfb__whyEsd-cbefored">
            <h2><span>REMEMBER</span>: "C" (cleaning) comes before “D” (disinfection)</h2>
            <p><span>Cleaning</span> is friction-scrubbing and rubbing which removes dirt/germs from surfaces/objects.</p>
            <p><span>Sanitizing</span> reduces the amount of germs by 99.9%, as per Health Canada’s standards.</p>
            <p><span>Disinfecting</span> kills most of the germs by 99.999% on surfaces/objects, preventing them from spreading. 
            Disinfectants need to be used after cleaning and must be left on the surface for the appropriate contact time to be effective.</p>
        </div>
    )
}

const WhyEsd = () => {
    return (
        <div className="qfb__whyEsd">
            <h2>Why Choose Electrostatic Spray Disinfection?</h2>
            <p>Our Electrostatic Spray Disinfection adds a level of protection to the most frequently touched surfaces and entire rooms like never before. 
                An ongoing disinfecting procedure is a requirement for any disease-prevention protocol. This is especially necessary during these times,
                 when we are facing a global pandemic. </p>
            <Table />
            <Features />
            <EsCustomers />
            <CbeforeD />
            <RightDisinfectant />
        </div>
    )
}

export default WhyEsd
