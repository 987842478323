import React, { useState } from 'react'
import './faq.css'
import { Link } from 'react-router-dom'
import { Members } from '../../components';





const Faq = () => {
    const [faqs, setFaqs] = useState([
        {
            question: "Do you use environmentally friendly products?",
            answer: "The environment is important to us and we have chosen to use certified EcoLogo cleaning products at no extra charge to our customers.",
            open: true
        },
        {
            question: "Are your employees insured and bonded?",
            answer: "All our employees are covered by our $5,000,000 (5 million) Commercial General Liability Insurance, as well as a $10,000 (10 thousand) Comprehensive Commercial Bond.",
            open: false
        },
        {
            question: "How long is the contract?",
            answer: "While we look forward to a long-term relationship with all our customers, our contract is month-to-month. You are not tied into a long-term contract only to see the quality of the service diminish after a few months. The contract may be terminated by giving 30 days written notice by either party.",
            open: false
        },
        {
            question: "Is the work supervised and do the same cleaners do the work?",
            answer: "Consistency is the key to quality work. Your building will be assigned to a supervised cleaning team and they will be the only people cleaning your building. In addition, our area supervisors do frequent on-site inspections to ensure service levels are maintained.",
            open: false
        },
        {
            question: "How frequently do you clean?",
            answer: "We will work with you to determine a program that will meet all your cleaning needs and which also fits in your budget. Frequencies can vary from daily to once a month. We also recommend and help establish a schedule for all extra services throughout the year.",
            open: false
        },
        {
            question: "Do your employees wear uniforms?",
            answer: "Yes, each employee is clearly identifiable wearing a Quality First uniform.",
            open: false
        },
        {
            question: "Do you supply your own cleaning products?",
            answer: "We provide all the cleaning equipment and cleaning supplies needed to perform the work. If requested, we can order and deliver (at no charge) consumer supplies such as garbage bags, paper products, soaps, room fresheners and deodorizers. These supplies are invoiced separately and ordered only as needed.",
            open: false
        },
        {
            question: "Do you clean residential houses?",
            answer: "We specialize in commercial properties only.",
            open: false
        },
    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if(i === index) {
                    faq.open = !faq.open;
                }else {
                    faq.open = false;
                }
                return faq
            })
        )
    }

    const FAQ = ({ faq, index, toggleFAQ }) => {
        return (
            <div className={"faq " + (faq.open ? "open" : "")} key={index} onClick={() => toggleFAQ(index)}>
                <div className="faq-question">{faq.question}</div>
                <div className="faq-answer">{faq.answer}</div>
            </div>
        )
    }

    return (
        <div className="qfb__faq">
            <div className="qfb__faq-header">
                <h1>FAQ</h1>
                <div className="qfb__faq-header-content">
                    <p>For hassle-free cleaning, the Lower Mainland turns to Quality First Building Maintenance Ltd, located in Delta. Browse through the list of the frequently asked
                        questions offered by our team. <Link to="/contact">Call or email us</Link> if you have a question that has not been answered on this page.</p>
                </div>
            </div>
            <div className="qfb__faq-content">
                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <div className="qfb__faq-moreHelp">
                <h2>Need More Help?</h2>
                <p>We offer free evaluations.</p>
                <Link to="/contact"><button>Contact Us</button></Link>
            </div>
            <Members />
        </div>
    )
}

export default Faq
