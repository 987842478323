import React from 'react'
import './extraServicesDetail.css'
import powerScrub from '../../assets/services/power-scrub.webp'

const Service1 = () => {
    return (
        <div className="qfb__extraServicesDetail-service">
            <h3>Strip, Seal and Wax Tile Floor</h3>
            <p>First floors are stripped, thoroughly washed and dried, and then 2 coats of protective sealer and 2 coats of wax are applied. 
                Floors are always allowed to dry completely between each coat. The floors are then buffed using a Clarke Ultra Speed 2000 DC electric 
                high-speed buffer that produces a brilliant high-gloss “wet look” shine. The matte finish is also available if requested.</p>
        </div>
    )
}

const Service2 = () => {
    return (
        <div className="qfb__extraServicesDetail-service">
            <h3>Buffing Floors</h3>
            <p>First floors thoroughly washed and dried. The floors are then buffed using a Clarke Ultra Speed 2000 DC electric high-speed buffer 
                that produces a brilliant high-gloss “wet look” shine. The dust control (DC) models are Clarke Green Certified. This new line of 
                Ultra Speed® Burnishers is ideal for use in hospitals, schools and areas requiring a dust-free, odour-free, quiet operation.</p>
                <p>Note – Please keep in mind buffing can only be done provided there is ample wax on the floor. 
                    If not, then the above service is done using 4 coats of wax instead of 2 sealers and 2 waxes.</p>
        </div>
    )
}

const Service3 = () => {
    return (
        <div className="qfb__extraServicesDetail-service row">
            <div className="qfb__extraServicesDetail-service_row">
                <h3>Power Scrub Ceramic Tile/Cement Floor</h3>
                <p>Using a 2-speed power scrubber, floors are scrubbed with the appropriate pad for the floor type. 
                    All the dirt and water is then removed with a high power wet/dry vac. 
                    The floors are again damp mopped and dried paying extra attention to get right into the corners.</p>
            </div>
            <img src={ powerScrub } alt="Power Scrub"/>
        </div>
    )
}

const Service4 = () => {
    return (
        <div className="qfb__extraServicesDetail-service">
            <h3>Steam Clean Carpets</h3>
            <p>A commercial-grade truck-mounted unit (CDS OverDrive™ 4.8 by Hydra Master) or a portable unit (200PSI Ninja hot water extractor by Esteam) 
                are used as access allows. The truck-mounted CDS Overdrive unit has new power train and drive technology which increases vacuum performance 
                by 25% while lowering gas consumption by 25% to 30%. The recovery tank and vacuum breaker designs have further increased usable airflow for 
                dramatically improved water recovery. Carpets are left cleaner and drier! The Esteam CSA-US approved 200 PSI NINJA portable extractors are 
                the world’s most popular extractors with performance and reliability like no other.</p>
        </div>
    )
}

const Service5 = () => {
    return (
        <div className="qfb__extraServicesDetail-service" id="extraServices-2">
            <h3>Window Cleaning – In and Out</h3>
            <p>We use a water feed pole system by Tucker Pole. First, a pre-soak agent is used, then soap is applied and scrubbed with a soft brush and 
                finally rinse and the dry agent is applied to leave no streak finish.</p>
        </div>
    )
}

const Service6 = () => {
    return (
        <div className="qfb__extraServicesDetail-service" id="extraServices-2">
            <h3>Blind Washing</h3>
            <p>Blinds are taken down, power steam washed outside, hung to dry and reinstalled.</p>
        </div>
    )
}

const Service7 = () => {
    return (
        <div className="qfb__extraServicesDetail-service" id="extraServices-2">
            <h3>Pressure Washing</h3>
            <p>A portable 11hp pressure washer is used taking care to use the appropriate nozzle for the surface being cleaned.</p>
        </div>
    )
}

const ExtraServicesDetail = () => {
    return (
        <div className="qfb__extraServicesDetail" id="extraServices">
            <h1><span>Extra Services</span> at Quality First Building Maintenance Ltd</h1>
            <Service1 />
            <Service2 />
            <Service3 />
            <Service4 />
            <Service5 />
            <Service6 />
            <Service7 />
        </div>
    )
}

export default ExtraServicesDetail
