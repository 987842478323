import React from 'react'
import './footer.css'
import logo from '../../assets/logo.png'

const Footer = () => {
    return (
        <div className="qfb__footer">
            <div className="qfb__footer-columns">
                <div className="qfb__footer-general">
                    <img src={logo} alt="logo"/>
                    <div className='qfb__footer-general-content'>
                        <div>
                            <h3>Location:</h3>
                            <p>233-9357 120th St</p>
                            <p>Delta, BC V4C 6R8</p>
                            <h3>Hours:</h3>
                            <p>Monday - Friday</p>
                            <p>9am - 5pm</p>
                        </div>
                       <div>
                            <h3>Contact:</h3>
                            <p>P: (604) 584-2103</p>
                            <p>F: (604) 584-2104</p>
                            <p>E: <a href="mailto:info@qfbml.com?">info@qfbml.com</a></p>
                       </div>
                    </div>
                    
                </div>
                <div className="qfb__footer-serviceArea">
                    <h2>Service Areas</h2>
                    <ul>
                        <li>Delta</li>
                        <li>Surrey</li>
                        <li>Vancouver</li>
                        <li>Coquitlam</li>
                        <li>Port Coquitlam</li>
                        <li>Ladner</li>
                        <li>White Rock</li>
                        <li>North Vancouver</li>
                        <li>West Vancouver</li>
                        <li>Port Moody</li>
                        <li>Tsawwassen</li>
                        <li>Cloverdale</li>
                        <li>Burnaby</li>
                        <li>Pitt Meadows</li>
                        <li>Richmond</li>
                        <li>Langley</li>
                        <li>New Westminster</li>
                        <li>Maple Ridge</li>
                    </ul>
                </div>
                <div className="qfb__footer-services">
                    <h2>Services</h2>
                    <ul>
                        <li>Strip, seal and wax tile floor</li>
                        <li>Buffing floor</li>
                        <li>Power scrub ceramic tile/cement floor</li>
                        <li>Steam clean carpet</li>
                        <li>Window cleaning – in and out</li>
                        <li>Blind washing</li>
                        <li>Pressure washing</li>
                    </ul>
                </div>
            </div>
            <div className="qfb__footer-copyright">
                <div>
                <p>&#169; <span>Quality First Building Maintenance Ltd.</span> All Rights Reserved.</p>
                </div>
                <div><p>Designed &#38; Developed by <span><a href="http://www.pathup.ca" rel="noreferrer" target="_blank">PathUp IT Solutions</a></span></p></div>
            </div>
        </div>
    )
}

export default Footer
