import React from 'react'
import { Footer } from './containers'
import { Navbar } from './components'
import { Home, Contact, Esd, Faq, Gallery, Services, Testimonials, WeGreen, WhyUs, CertifiedELProducts } from './pages'
import './App.css'
import { Routes, Route } from 'react-router-dom'
import ScrollToTop from './scrollToTop'
import { ScrollToTopButton } from './components'

const App = () => {
    return (
        <div className="App">
                <div className="bg">
                    <Navbar />                 
                </div>
                <ScrollToTop />
                <Routes>
                    <Route exact path="/" element={< Home />} />
                    <Route exact path="/about" element={<WhyUs />} />
                    <Route exact path="/services" element={<Services />} />
                    <Route exact path="/infection-control" element={<Esd />} />
                    <Route exact path="/green" element={<WeGreen />} />
                    <Route exact path="/green-products" element={<CertifiedELProducts />} />
                    <Route exact path="/faq" element={<Faq />} />
                    <Route exact path="/testimonials" element={<Testimonials />} />
                    <Route exact path="/gallery" element={<Gallery />} />
                    <Route exact path="/contact" element={<Contact />} />
                </Routes>
                <ScrollToTopButton />
                <Footer />
        </div>
    )
}

export default App
