import React, { useState } from 'react'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import logo from '../../assets/logo_text1.webp'
import './navbar.css'
import phone from '../../assets/phone.svg'
import { Link, NavLink } from 'react-router-dom'



const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);

    const Menu = () => (
        <>
        <div>
        <NavLink to="/" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
               isPending ? "pending" : isActive ? "active" : ""
            }
        >
        Home
        </NavLink>
        </div>

        <div>
        <NavLink to="/about" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
               isPending ? "pending" : isActive ? "active" : ""
            }
        >
        Why Choose Us?
        </NavLink>
        </div>
        
        <div>
        <NavLink to="/services" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
               isPending ? "pending" : isActive ? "active" : ""
            }
        >
        Services
        </NavLink>
        </div>

        <div>
        <NavLink to="/infection-control" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
               isPending ? "pending" : isActive ? "active" : ""
            }
        >
        Infection Control Program - ESD
        </NavLink>
        </div>
        

        <div className="qfb__navbar-links_container-submenu">
            <NavLink to="/green" onClick={()=> setToggleMenu(false)}
                className={({ isActive, isPending }) => 
                isPending ? "pending" : isActive ? "active" : ""
                }
            >
            We Are Green
            </NavLink>
            <div class="qfb__navbar-links_container-submenu_content">
                <NavLink to="/green-products" onClick={()=> setToggleMenu(false)}
                className={({ isActive, isPending }) => 
                isPending ? "pending" : isActive ? "active" : ""
                }
                >
                Certified Eco Logo Products
                </NavLink><i class="fa fa-caret-down"></i>
            </div>
        </div>

        <div>
        <NavLink to="/faq" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
               isPending ? "pending" : isActive ? "active" : ""
            }
        >
        FAQ
        </NavLink>
        </div>

        <div>
        <NavLink to="/testimonials" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
               isPending ? "pending" : isActive ? "active" : ""
            }
        >
        Testimonials
        </NavLink>
        </div>

        <div>
        <NavLink to="/gallery" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
               isPending ? "pending" : isActive ? "active" : ""
            }
        >
        Gallery
        </NavLink>
        </div>

        <div>
        <NavLink to="/contact" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
               isPending ? "pending" : isActive ? "active" : ""
            }
        >
        Contact Us
        </NavLink>
        </div>

        {/*
        <p><Link to="/" onClick={()=> setToggleMenu(false)}>Home</Link></p>
        <p><Link to="/about" onClick={()=> setToggleMenu(false)}>Why Choose Us?</Link></p>
        <p><Link to="/services" onClick={()=> setToggleMenu(false)}>Services</Link></p>
        <p><Link to="/infection-control" onClick={()=> setToggleMenu(false)}>Infection Control Program - ESD</Link></p>
        <div className="qfb__navbar-links_container-submenu">
            <p class="subnavbtn"><Link to="/green" onClick={()=> setToggleMenu(false)}>We Are Green</Link></p>
            <div class="qfb__navbar-links_container-submenu_content">
                <p><Link to="/green-products" onClick={()=> setToggleMenu(false)}>Certified Eco Logo Products</Link><i class="fa fa-caret-down"></i></p>
            </div>
        </div>
        <p><Link to="/faq" onClick={()=> setToggleMenu(false)}>FAQ</Link></p>
        <p><Link to="/testimonials" onClick={()=> setToggleMenu(false)}>Testimonials</Link></p>
        <p><Link to="/gallery" onClick={()=> setToggleMenu(false)}>Gallery</Link></p>
        <p><Link to="/contact" onClick={()=> setToggleMenu(false)}>Contact Us</Link></p>

        */}
        </>
    )


    const SubMenu = () => (
        <>
      
        <NavLink to="/" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
               isPending ? "pending" : isActive ? "active" : ""
            }
        >
        Home
        </NavLink>

        <NavLink to="/about" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
               isPending ? "pending" : isActive ? "active" : ""
            }
        >
        Why Choose Us?
        </NavLink>
        
        <NavLink to="/services" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
               isPending ? "pending" : isActive ? "active" : ""
            }
        >
        Services
        </NavLink>

        <NavLink to="/infection-control" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
               isPending ? "pending" : isActive ? "active" : ""
            }
        >
        Infection Control Program - ESD
        </NavLink>
        

       
        <NavLink to="/green" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
            isPending ? "pending" : isActive ? "active" : ""
            }
        >
        We Are Green
        </NavLink>

        <NavLink to="/green-products" onClick={()=> setToggleMenu(false)}
        className={({ isActive, isPending }) => 
        isPending ? "pending" : isActive ? "active" : ""
        }
        >
        Certified Eco Logo Products
        </NavLink><i class="fa fa-caret-down"></i>

        <NavLink to="/faq" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
               isPending ? "pending" : isActive ? "active" : ""
            }
        >
        FAQ
        </NavLink>

        <NavLink to="/testimonials" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
               isPending ? "pending" : isActive ? "active" : ""
            }
        >
        Testimonials
        </NavLink>

        <NavLink to="/gallery" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
               isPending ? "pending" : isActive ? "active" : ""
            }
        >
        Gallery
        </NavLink>

        <NavLink to="/contact" onClick={()=> setToggleMenu(false)}
            className={({ isActive, isPending }) => 
               isPending ? "pending" : isActive ? "active" : ""
            }
        >
        Contact Us
        </NavLink>
        </>
    )

    return (
        <div className="qfb__navbar">
            <div className="qfb__navbar-links_logo-container">
                <div className="qfb__navbar-links_logo">
                    <Link to="/"><img src={logo} alt="logo"/></Link>
                </div>
                <div className="qfb__navbar-links_logo-phone">
                    <p>Contact us for a free quote</p>
                    <div className="qfb__navbar-links_logo-phone_content">
                        <img src={ phone } alt="Phone"/>
                        <p><a href="tel:604-584-2103">(604) 584-2103</a></p>
                    </div>
                    <Link to="/contact"><button>Get A Quote</button></Link>
                </div>
                <div className="qfb__navbar-menu_phone">
                    <div className='qfb__navbar-menu_phone-link'>
                        <a href="tel:604-584-2103"><img src={ phone } alt="Phone"/></a>
                        <a href="tel:604-584-2103"> (604) 584-2103</a>
                    </div>
                    <Link to="/contact"><button>Get A Quote</button></Link>
                </div>
            </div>
            <div className="qfb__navbar-links">          
                <div className="qfb__navbar-links_container">
                    <Menu />
                </div>
            </div>
            <div className="qfb__navbar-menu">
                {toggleMenu
                    ? <RiCloseLine color="#434343" size={27} onClick={()=> setToggleMenu(false)}/>
                    : <RiMenu3Line color="#434343" size={29} onClick={()=> setToggleMenu(true)}/>
                }
                {toggleMenu && (
                    <div className="qfb__navbar-menu_container scale-up-center">
                        <div className="qfb__navbar-menu_container-links">
                            <SubMenu />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Navbar
