import React from 'react'
import './products.css'
import vertAllPurpose from '../../files/ugd/1Vert2GoAllPurposeCleaner.pdf'
import vertWashroomCleaner from '../../files/ugd/2Vert2GoWashroomCleaner.pdf'
import vertCarpetFabricCleaner from '../../files/ugd/6Vert2GoBioPre-SprayCarpetFabricCleaner.pdf'
import vertOdourControl from '../../files/ugd/4Vert2GoBioOdourControl.pdf'
import vertFloorDegreaser from '../../files/ugd/5Vert2GoBioFloorDegreaser.pdf'
import vertNeutraStrip from '../../files/ugd/7Vert2GoNeturaStrip.pdf'
import eternalProFloorPolish from '../../files/ugd/8EternalProFloorFinish.pdf'
import neutralCleaner from '../../files/ugd/3ProFreshNeutralCleaner.pdf'
import bioBacII from '../../files/ugd/3Bio-Bac-BioII-BasedCleanerDegreaserDeodorizer.pdf'
import excelsior from '../../files/ugd/2Excelsior-GeneralPurposeHardSurfaceCleaner.pdf'
import oxyDST from '../../files/ugd/1OXYD.S.T.-HydrogenPeroxideBasedCleaner.pdf'
import quatPlus from '../../files/ugd/13QuatPlus-LiquidDisinfectantGermicideInfluenzaA-H1N1.pdf'
import polar from '../../files/ugd/5Polar-CreamCleaner.pdf'
import tradition from '../../files/ugd/7TraditionPlus-FoamingHandCleaner.pdf'
import traditionPlus from '../../files/ugd/6Tradition-LiquidHandCleaner.pdf'
import azure from '../../files/ugd/8-Azure3.pdf'
import emerald from '../../files/ugd/4Emerald-HardSurfaceCleanerDegreaser.pdf'
import orbit from '../../files/ugd/11Orbit-AllSystemsFloorFinish.pdf'
import timeOutPlus from '../../files/ugd/9Time-OutPlusPowerfulSuperStripper.pdf'
import foundation from '../../files/ugd/10Foundation-FloorSealer.pdf'
import ecoMaxUltra from '../../files/ugd/1Eco-MaxUltraMulti-OdourPurposeCleaner.pdf'
import ecoMaxScentFree from '../../files/ugd/2Eco-MaxScentFreeUltraMulti-PurposeCleaner.pdf'
import ecoMaxNaturalBowl from '../../files/ugd/3Eco-MaxNaturalBowlCleaner.pdf'

const Sheets = () => {
    return (
        <div className="qfb__products-sheets">
            <h2>Click on a Product to View MSDS Sheet</h2>
            <div className="qfb__products-sheets_row">
                <div className="qfb__products-sheets_column">
                    <p>Enzyme &#38; Microbial Based</p>
                    <ul>
                        <li><a href = { vertAllPurpose } rel="noreferrer" target="_blank">Vert-2-Go All Purpose Cleaner</a></li>
                        <li><a href = { vertWashroomCleaner } rel="noreferrer" target="_blank">Vert-2-Go Washroom Cleaner</a></li>
                        <li><a href={ vertCarpetFabricCleaner } rel="noreferrer" target="_blank">Vert-2-Go Bio Pre-Spray Carpet &#38; Fabric Cleaner/Deodorizer</a></li>
                        <li><a href={ vertOdourControl } rel="noreferrer" target="_blank">Vert-2-Go Bio Odour Control</a></li>
                        <li><a href={ vertFloorDegreaser } rel="noreferrer" target="_blank">Vert-2-Go Bio Floor Degreaser</a></li>
                        <li><a href={ vertNeutraStrip } rel="noreferrer" target="_blank">Vert-2-Go Neutra Strip</a></li>
                        <li><a href={ eternalProFloorPolish } rel="noreferrer" target="_blank">Eternal Pro Floor Finish</a></li>
                        <li><a>Blue Thunder</a></li>
                        <li><a href={ neutralCleaner } rel="noreferrer" target="_blank">Pro Fresh Neutral Cleaner</a></li>
                    </ul>
                </div>
                <div className="qfb__products-sheets_column">
                    <p>100 % Biodegradable in 5 days</p>
                    <ul>
                        <li><a href={ bioBacII } rel="noreferrer" target="_blank">Bio-Bac-Bio II-Based Cleaner, Degreaser &#38; Deodorizer</a></li>
                        <li><a href={ excelsior } rel="noreferrer" target="_blank">Excelsior-General Purpose Hard Surface Cleaner </a></li>
                        <li><a href={ oxyDST } rel="noreferrer" target="_blank">OXY D.S.T. Hydrogen Peroxide Based Cleaner</a></li>
                        <li><a href={ quatPlus } rel="noreferrer" target="_blank">Quat Plus-Liquid Disinfectant Germicide (Influenza A-H1N1)</a></li>
                        <li><a href={ polar } rel="noreferrer" target="_blank">Polar-Cream Cleaner</a></li>
                        <li><a href={ tradition } rel="noreferrer" target="_blank">Tradition-Liquid Hand Cleaner</a></li>
                        <li><a href={ traditionPlus } rel="noreferrer" target="_blank">Tradition Plus Foaming Hand Cleaner</a></li>
                        <li><a href={ azure } rel="noreferrer" target="_blank">Azure M3-Window &#38; Glass Cleaner</a></li>
                        <li><a href={ emerald } rel="noreferrer" target="_blank">Emerald-Hard Surface Cleaner &#38; Degreaser</a></li>
                        <li><a href={ orbit } rel="noreferrer" target="_blank">Orbit All Systems Floor Finish </a></li>
                        <li><a href={ timeOutPlus } rel="noreferrer" target="_blank">Time Out Plus Powerful Floor Stripper</a></li>
                        <li><a href={ foundation } rel="noreferrer" target="_blank">Foundation-Floor Sealer</a></li>
                    </ul>
                </div>
                <div className="qfb__products-sheets_column">
                    <p>Scent Free</p>
                    <ul>
                        <li><a href={ ecoMaxUltra } rel="noreferrer" target="_blank">Eco-Max Ultra Multi-Purpose Cleaner &#38; Odour Neutralizer</a></li>
                        <li><a href={ ecoMaxScentFree } rel="noreferrer" target="_blank">Eco-Max Scent Free Ultra Multi-Purpose Cleaner</a></li>
                        <li><a href={ ecoMaxNaturalBowl } rel="noreferrer" target="_blank">Eco-Max Natural Bowl Cleaner</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

const Products = () => {
    return (
        <div className="qfb__products">
            <div className="qfb__products-booklet">
                <h2><a href="https://www.dustbane.ca/sustainability" rel="noreferrer" target="_blank">Click here to view the <br /> “Ecological Information Booklet”</a></h2>
            </div>
            <Sheets />
        </div>
    )
}

export default Products
